<template>
  <div class="page">
    <!-- 搜索条件表单 -->
    <el-form class="ser_form" size="small" label-width="80px">
      
      <el-form-item class="el_form_item elfrom_item" label="发起时间">
        <div class="block">
          <el-date-picker
            v-model="date_value"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right"
            value-format="timestamp"
          >
          </el-date-picker>
        </div>
      </el-form-item>
	  
	  <el-form-item class="el_form_item elfrom_item"> </el-form-item>
	  
	  <el-form-item class="el_form_item" label="流水编号">
	    <el-input
	      class="el_input"
	      v-model="form.ra_num"
	      placeholder="流水编号"
	      clearable
	    ></el-input>
	  </el-form-item>
	  
	  <el-form-item class="el_form_item" label="描述">
	    <el-input
	      class="el_input"
	      v-model="form.info"
	      placeholder="描述"
	      clearable
	    ></el-input>
	  </el-form-item>
	  
      <el-form-item class="el_form_item elfrom_item"> </el-form-item>
	  
      <el-form-item label-width="0">
        <el-button type="primary" @click="cargo_ser">查询</el-button>
        <el-button type="primary" @click="ser_para_clear">清空条件</el-button>
        <el-button type="primary" @click="open_Confirm()">批量通过</el-button>
        <!-- <el-button type="primary" @click="see_test">弹框测试</el-button> -->
      </el-form-item>
    </el-form>

    
    <!-- 表格 -->
    <div class="tab_height">
      <el-table
        :data="list.data"
        :border="true"
        :stripe="true"
        size="small"
        v-loading="list.loading"
        @selection-change="select_table"
      >
        <el-table-column type="selection" width="40" align="center">
        </el-table-column>
        <el-table-column label="姓名">
          <template slot-scope="scope">
            <div class="tab_line_item">
            {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
		
		<el-table-column label="卡号">
		  <template slot-scope="scope">
		    <div class="tab_line_item">
		    {{ scope.row.card_num }}
		    </div>
		  </template>
		</el-table-column>
		
		<el-table-column label="金额">
		  <template slot-scope="scope">
		    <div class="tab_line_item">
		    {{ scope.row.money }}
		    </div>
		  </template>
		</el-table-column>
		
		<el-table-column label="银行名称">
		  <template slot-scope="scope">
		    <div class="tab_line_item">
		    {{ scope.row.bank_name }}
		    </div>
		  </template>
		</el-table-column>
		
		<el-table-column label="附言1">
		  <template slot-scope="scope">
		    <div class="tab_line_item">
		    {{ scope.row.fuyan1 }}
		    </div>
		  </template>
		</el-table-column>
		
		<el-table-column label="附言2">
		  <template slot-scope="scope">
		    <div class="tab_line_item">
		    {{ scope.row.fuyan2 }}
		    </div>
		  </template>
		</el-table-column>
		
		<el-table-column label="时间">
		  <template slot-scope="scope">
		    <div class="tab_line_item">
		    {{ scope.row.time }}
		    </div>
		  </template>
		</el-table-column>
		
		<el-table-column label="状态">
		  <template slot-scope="scope">
		    <div class="tab_line_item" v-if="(scope.row.state==1)" >未处理</div>
		    <div class="tab_line_item" v-if="(scope.row.state==2)" >已处理</div>
		  </template>
		</el-table-column>
		<el-table-column label="对应流水号">
		  <template slot-scope="scope">
		    <div class="tab_line_item">
		    {{ scope.row.ra_num }}
		    </div>
		  </template>
		</el-table-column>
		
		<el-table-column label="描述">
		  <template slot-scope="scope">
		    <div class="tab_line_item">
		    {{ scope.row.info }}
		    </div>
		  </template>
		</el-table-column>
		
		

        <!-- 操作行 -->
        <el-table-column fixed="right" label="操作" width="80" align="center">
          <template slot-scope="scope">
            <div>
              <el-button
				@click="done(scope.row.num,scope.$index)"
                class="btn_left"
                size="mini"
                type="text"
                >处理</el-button
              >
            </div>

          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 回到顶部 -->
    <el-backtop target=".page" :bottom="70" :right="120"></el-backtop>
    <!-- 分页 -->
    <el-pagination
      class="margin-top-xl"
      @size-change="page_size_change"
      @current-change="page_current_change"
      :current-page="page.p"
      :page-sizes="[10, 100, 200, 500]"
      :page-size="page.num"
      :total="list.total * 1"
      layout="total, sizes,prev, pager, next, jumper"
    >
    </el-pagination>
    
  </div>
</template>

<script>
import { regionData, CodeToText } from "element-china-area-data";
export default {
  data() {
    return {
      driver_status_text: "",
      truck_info_status_text: "",
      //地图查看参数
      map: {
        num: 0,
        is_show: false,
        start: "",
        end: "",
        start_province: "",
        ed_province: "",
      },
      total: {},
      //查看轨迹参数
      maptrajectory: {
        type: 0,
        num: 0,
        is_show: false,
        plate_num: "", //车牌号
        start_time: "", // 开始时间
        end_time: "", // 结束时间
        start: "", //发车
        end: "", //到货
        aim_prov: "", //到货地
        case_prov: "", //发货地
      },
      //搜索条件参数
      ser_para: {
        // cargo_type:[],//货物类型
        outlets_list: [], //网点列表
        cities: regionData, //省市区数据
      },
      trans_status: "1",
      hd_status: "1",
      //搜索条件
      form: {
		ra_num:"",
		info:"",
        truck_tord_num: "", //货源编号
        out_trade_num: "", //上游编号
        group1_num: "", //组别编号
        shipper_tel: "", //货主手机号
        truck_owner_tel: "", //车主手机号
        driver_tel: "", //司机手机号
        truck_plate_num: "", //车牌号
        cargo_name: "", //货物名称
        payed_status: "", //支付状态
        //settlement_type: "", //结算方式
        is_need_return: "", //是否回程
        case_prov: "", //装货地省份
        case_city: "", //装货地城市
        case_county: "", //装货地县区
        aim_prov: "", //到货地省份
        aim_city: "", //到货地城市
        aim_county: "", //到货地县区
        check_state: "", //审核状态
        case_cities: null, //装货地省市县
        aim_cities: null, //到货地省市县
        outlets_num: "", //网点编号
        creat_time_start: "", // 开始时间
        creat_time_end: "", // 结束
        truck_check_status: "", //车辆审核状态
        driver_check_status: "", //司机审核状态
      },
      ignore: "忽略",
      date_value: "", //时间选择
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      // 货车审核弹窗
      truck_info: {
        is_show: 0,
        data: {},
      },
      // 司机审核弹窗数据
      driver_info: {
        is_show: 0,
        data: {},
      },
      //表格数据
      list: {
        loading: false, //loading特效
        data: [], //列表数据
        total: 0, //列表总记录数
        distance: 0, //距离
      },
      //页码信息
      page: {
        need_num: 1,
        num: 10,
        p: 1,
      },

      //凭证相关
      voucher: {
        is_show: 0,
        truck_tord_num: "",
      },
      //修改相关
      edit: {
        is_show: 0,
        tord_info: {},
      },

      //批量审核
      openConfirm: {
        truck_tord_num: "",
        is_show: 0,
      },
      Payment: {
        is_show: 0,
        truck_tord_num: "",
      },
    };
  },
  created() {

    //读取货源
    this.get_page_data();
  },
  methods: {
	  
	//搜索
	cargo_ser() {
		if (this.date_value) {
			this.form.creat_time_start = parseInt(this.date_value[0] / 1000);
			this.form.creat_time_end = parseInt(this.date_value[1] / 1000);
			this.page.p = 1;
			this.get_page_data();
		} else {
			this.form.creat_time_start = "";
			this.form.creat_time_end = "";
			this.page.p = 1;
			this.get_page_data();
		}
	},
	
    //数据勾选
    select_table(item) {
      var truck_tord_num = [];
      item.forEach((item) => {
        truck_tord_num.push(item.truck_tord_num);
      });
      this.openConfirm.truck_tord_num = truck_tord_num;
    },
	
    //清空查询条件
    ser_para_clear() {
      this.form = {
        truck_tord_num: "", //货源编号
        driver_tel: "", //车主手机号
        truck_plate_num: "", //车牌号
        cargo_name: "", //货物名称
        cargo_type: "", //货物性质
        case_prov: "", //装货地省份
        case_city: "", //装货地城市
        case_county: "", //装货地县区
        aim_prov: "", //到货地省份
        aim_city: "", //到货地城市
        aim_county: "", //到货地县区
        case_cities: null, //装货地省市县
        aim_cities: null, //到货地省市县
        truck_check_status: "", //车辆审核状态
        driver_check_status: "", //司机审核状态
        check_status: "",
      };
      this.get_page_data();
    },

    //页宽变化
    page_size_change(num) {
      //置入页码
      this.page.num = num;

      //读取
      this.get_page_data();
    },

    //页码变化
    page_current_change(p) {
      //置入页码
      this.page.p = p;

      //读取
      this.get_page_data();
    },
	
	done(num,index){
		let that=this;
		this.$my.net.req({
		  take_over_control: 1,
		  data: {
		    mod: "finance",
		    ctr: "hsbank_refund_done",
		    num:num,
			...this.form,
		  },
		  callback: (data) => {
			if(that.list.data){
				that.list.data[index].info=that.form.info;
			}
			
			// that.get_page_data();
		  },
		})
	},

    //获取货源列表
    get_page_data() {
      //加载中...
      if (this.list.loading) {
        return;
      }

      //置为加载中
      this.list.loading = true;
		
		
      //读取数据
      this.$my.net.req({
        take_over_control: 1,
        data: {
          mod: "finance",
          ctr: "hsbank_refund",
          ...this.form,
          ...this.page,
          trans_status: this.trans_status,
          hd_status: this.hd_status,
        },
        callback: (data) => {
			this.list.loading = false;
		  
			
			
          if (data.code == 1) {
            this.list.loading = false;
            this.total.max = "0", 
            this.total.freight_total = "0", 
            this.total.cash_total = "0", 
            this.total.fuel_total = "0", 
            this.list.total = "";
            this.list.data = [];
          } else {
            this.total = data.msg.total;

            //总数
            this.list.total = [];

            
            //渲染
            this.list.data = data.msg;
			
			this.list.loading = false;
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  height: calc(100% - 40px);
  overflow: auto;
}
.ser_form {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
.tab_height {
  //height: calc(100% - 184px);
}
.el_form_item {
  width: 275px;
}
.el_input {
  width: 94%;
}
.btn_left {
  margin: 0;
}
.total_info {
  text-align: right;
  font-size: 12px;
  margin-bottom: 24px;
  margin-right: 1px;
  margin-top: 10px;
  color: #606266;
  span {
    margin-left: 20px;
  }
}
.item {
  font-weight: 800;
}
</style>